.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Blue background for user's current selections */
.bg-blue-500 {
  background-color: #3b82f6 !important; /* Bright blue */
  color: #ffffff !important; /* White text */
}

/* Custom heat color scale for selected dates */
/* 1 selection: Lightest Green */
.bg-heat-1 {
  background-color: #e6f3e6 !important;
}

/* 2 selections: Light Green */
.bg-heat-2 {
  background-color: #c2e0c2 !important;
}

/* 3 selections: Medium Green */
.bg-heat-3 {
  background-color: #8cd98c !important;
}

/* 4 selections: Dark Green */
.bg-heat-4 {
  background-color: #4caf50 !important;
  color: #ffffff !important; /* Ensure white text for readability */
}

/* 5+ selections: Darkest Green */
.bg-heat-5 {
  background-color: #2e7d32 !important;
  color: #ffffff !important; /* Ensure white text for readability */
}

.react-calendar__month-view__days__day--weekend {
  background-color: #fff;
  color: #000000;
}

.dashboard-background {
  background-color: #f3f4f6;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full screen height to center the loader */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1); /* Light grey border */
  border-top: 4px solid #3498db; /* Blue color for the spinner */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite; /* Infinite rotation */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

form input,
form button {
  height: 50px !important;
  align-items: center;
}

.hero-pattern {
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23e0e0e0' fill-opacity='0.4'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-position: right;
}

.hero {
  position: relative;
  width: 100%;
  height: 500px; /* Adjust based on your hero height */
  background-color: #f9fafb; /* Your hero background */
}

.scribble {
  position: absolute;
  z-index: -1;
  opacity: 0.7;
}

/* Positioning the scribbles */
.scribble-1 {
  top: 10px;
  left: 20%;
  transform: rotate(10deg);
}

.scribble-2 {
  top: 150px;
  right: 20%;
  transform: rotate(-20deg);
}

.scribble-3 {
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%) rotate(30deg);
}

.scribbles-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1; /* Shapes behind the text but above background */
}

.hero-text {
  letter-spacing: -3px !important;
}

.bg-conic-gradient {
  background: linear-gradient(
    90deg,
    #44ff9a -0.55%,
    #44b0ff 22.86%,
    #8b44ff 48.36%,
    #ff6644 73.33%,
    #ebff70 99.34%
  );
}
