@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "SF PRO";
  src: url("./assets/fonts/SFPRODISPLAYREGULAR.OTF") format("opentype"); /* Path to the .otf file */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SF PRO BOLD";
  src: url("./assets/fonts/SFPRODISPLAYBOLD.OTF") format("opentype");
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #0d0d0d; */
}

h1,
h2,
h3,
h4 {
  font-family: "Inter", sans-serif;
  letter-spacing: -1px !important;
}

.react-calendar__tile,
.react-calendar__tile abbr {
  font-family: "Inter", sans-serif !important;
}

/* Override react-calendar range classes */
.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeEnd,
.react-calendar__tile--rangeBothEnds {
  background-color: inherit !important; /* Remove background color for range selections */
  color: inherit !important; /* Remove text color changes for range selections */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

.rdp-day_button {
  width: 100%;
  height: 100%;
  color: black;
}

.rdp-range_start .rdp-day_button,
.rdp-range_end .rdp-day_button {
  background-color: #050505 !important;
  border: none !important;
  border-radius: 5px;
}

.rdp-range_middle {
  background: #eeeeee !important;
}

.rdp-root {
  --rdp-font-family: "Inter" !important;
}

.rdp-chevron {
  fill: black !important;
}

.rdp-month_caption {
  align-items: center;
}
